





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { NavigationItem } from '@/models/NavigationItem'

@Component({
  components: {}
})
export default class Tabs extends Vue {
  @Prop()
  items: NavigationItem[]

  isSelected(item: NavigationItem): boolean {
    return this.$route.name.includes(item.name)
  }

  change(event: any) {
    const item = this.items.find((i) => i.text === event.target.value)
    this.$router.push({ name: item.name })
  }
}
