var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Content',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_vm._l((_vm.farms),function(farm){return _c('div',{key:farm.id,staticClass:"flex flex-col p-0 md:flex-row box"},[_c('div',{staticClass:"flex-shrink-0 h-64 border-b md:border-b-0 md:border-r md:w-1/3 border-brand-300"},[_c('router-link',{attrs:{"to":farm.detailed ? { name: 'farm', params: { slug: farm.slug } } : ''}},[(farm.photoUrl)?_c('img',{staticClass:"object-cover w-full h-full",attrs:{"loading":"lazy","src":farm.photoUrl}}):_vm._e()])],1),_c('div',{staticClass:"flex flex-col w-full px-4 py-4 space-y-6 md:px-6 md:py-4"},[_c('div',{staticClass:"flex flex-col"},[_c('router-link',{attrs:{"to":farm.detailed
                ? { name: 'farm', params: { slug: farm.slug } }
                : ''}},[_c('h3',{staticClass:"text-2xl font-bold md:text-3xl text-brand-500",class:{ link: farm.detailed }},[_vm._v(_vm._s(farm.name))])]),_c('div',{staticClass:"flex flex-col md:space-x-2 md:flex-row"},[_c('span',{staticClass:"text-lg text-gray-500"},[_vm._v(_vm._s(farm.street)+", "+_vm._s(farm.zip)+" "+_vm._s(farm.city))]),(farm.distance)?_c('span',{staticClass:"font-medium text-brand-500"},[_vm._v("(~"+_vm._s(_vm._f("number")(farm.distance))+"km entfernt)")]):_vm._e()])],1),_c('div',{staticClass:"flex flex-col justify-between flex-1 space-y-6 md:space-x-12 md:space-y-0 md:flex-row"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-col space-y-1"},[(farm.nextSlaughterDay)?_c('span',{staticClass:"text-gray-900"},[_vm._v(" Schweine verfügbar ab "),_c('span',{staticClass:"font-medium text-brand-500"},[_vm._v(" "+_vm._s(_vm._f("date")(farm.nextSlaughterDay))+" ")])]):_vm._e(),(farm.sectors && farm.capacity)?_c('span',{staticClass:"text-gray-900"},[_c('span',{staticClass:"font-medium text-brand-500"},[_vm._v(" "+_vm._s(farm.sectors)+" ")]),_vm._v(" Buchten mit Platz für "),_c('span',{staticClass:"font-medium text-brand-500"},[_vm._v(" "+_vm._s(farm.capacity)+" ")]),_vm._v(" Schweine ")]):_vm._e()]),_c('div',[_c('p',{staticClass:"text-gray-500"},[_vm._v(_vm._s(farm.shortDescription))])])]),_c('div',{staticClass:"flex items-end justify-end flex-shrink-0 space-x-2"},[(farm.hasShop && !farm.soldOut)?_c('router-link',{staticClass:"w-full md:w-auto",attrs:{"to":{
                name: 'products',
                query: {
                  farm_id: farm.id
                }
              }}},[_c('Button',{staticClass:"w-full px-6 md:text-lg button button--brand md:w-auto"},[_vm._v("Jetzt einkaufen")])],1):_c('Button',{staticClass:"w-full px-6 md:text-lg button button--default md:w-auto",attrs:{"disabled":true}},[_vm._v("Aktuell ausverkauft")])],1)])])])}),_c('div',{staticClass:"flex flex-col p-0 md:flex-row box"},[_c('div',{staticClass:"flex items-center justify-center flex-shrink-0 h-64 border-b md:border-b-0 md:border-r md:w-1/3 border-brand-300"},[_c('img',{staticClass:"object-cover w-64",attrs:{"loading":"lazy","src":require("@/assets/undraw_farm_girl_dnpe.svg")}})]),_c('div',{staticClass:"flex flex-col w-full px-4 py-4 space-y-6 md:px-6 md:py-4"},[_c('div',{staticClass:"flex flex-col"},[_c('h3',{staticClass:"text-2xl font-bold md:text-3xl text-brand-500"},[_vm._v("Hier könnte dein Betrieb stehen")]),_c('div',{staticClass:"flex flex-col items-start"},[_c('span',{staticClass:"text-lg text-gray-500"},[_vm._v(" Werde jetzt meinSCHWEIN Partner. Bei Interesse wende dich bitte an: ")]),_c('span',{staticClass:"space-x-2 font-medium text-brand-500"},[_c('a',{staticClass:"link",attrs:{"href":"tel:+436643933883"}},[_vm._v("+43 664 39 33 883")]),_c('span',{staticClass:"text-gray-900"},[_vm._v("oder")]),_c('a',{staticClass:"link",attrs:{"href":"mailto:info@meinschwein.at"}},[_vm._v("info@meinschwein.at")])])])]),_c('div',{staticClass:"flex flex-col justify-between flex-1 space-y-6 md:space-x-12 md:space-y-0 md:flex-row"},[_c('div',{staticClass:"flex items-end justify-end flex-shrink-0 w-full space-x-2"},[_c('router-link',{staticClass:"w-full md:w-auto",attrs:{"to":"/info#landwirte"}},[_c('Button',{staticClass:"w-full px-6 text-lg button button--brand md:w-auto"},[_vm._v("Mehr erfahren")])],1)],1)])])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }