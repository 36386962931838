
















































































































































import { Component, Vue } from 'vue-property-decorator'
import Content from '@/components/Content.vue'
import Loading from '@/components/Loading.vue'
import Tabs from '@/components/Tabs.vue'
import Button from '@/components/Button.vue'
import { Farm } from '@/models/Farm'
import { firestore } from 'firebase/app'
import { FarmConverter } from '@/models/converters/FarmConverter'
import { FarmPeriodConverter } from '@/models/converters/FarmPeriodConverter'
import { calculateDistance } from '@/services/Position'
import { mainStore } from '@/store'

@Component({
  components: {
    Content,
    Loading,
    Tabs,
    Button
  }
})
export default class Farms extends Vue {
  loading: boolean = false
  farms: Farm[] = []
  searchTerm: string = ''

  async mounted() {
    if (mainStore.farms.length > 0) {
      this.farms = mainStore.farms
      return
    }

    this.loading = true

    this.farms = (
      await firestore()
        .collection('farms')
        .where('listed', '==', true)
        .orderBy('order', 'asc')
        .withConverter(new FarmConverter())
        .get()
    ).docs.map((doc) => doc.data())

    for (const farm of this.farms) {
      const periods = (
        await firestore()
          .collection('farms')
          .doc(farm.id)
          .collection('periods')
          .withConverter(new FarmPeriodConverter())
          .where('slaughter_day', '>=', new Date())
          .orderBy('slaughter_day', 'asc')
          .get()
      ).docs.map((doc) => doc.data())

      if (periods.length > 0) {
        const period =
          periods.filter((p) => p.availableCapacity > 0)[0] ?? periods[0]

        farm.nextSlaughterDay = period.slaughterDay
      }
      farm.soldOut = periods.filter((p) => p.availableCapacity > 0).length === 0
    }

    mainStore.setFarms(this.farms)
    this.loading = false

    this.sortFarmsByClosest()
  }

  sortFarmsByClosest() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.farms = this.farms.map((farm) => {
          farm.distance = Math.ceil(
            Math.ceil(
              calculateDistance(
                farm.lat,
                farm.long,
                position.coords.latitude,
                position.coords.longitude
              )
            ) / 1000
          )

          return farm
        })

        this.farms.sort((a, b) => {
          return a.distance - b.distance
        })
        mainStore.setFarms(this.farms)
      })
    }
  }
}
